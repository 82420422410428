import React from "react";
import { string, shape } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import Heading from "../Base/HeadingBuilder";
import CtaPrimary from "../Base/CtaPrimary";
import desktopBg from "../../images/global_assets/request-int-desktop-bg.png";
import mobileBg from "../../images/global_assets/request-int-mobile-bg.png";
import useWindowSize from "../../hooks/use-window-size";

const RequestIntegration = ({ className, title, subTitle, cta }) => {
  const { width } = useWindowSize();
  return (
    <section
      className={`${className} 
      relative text-center
      pt-52 sm:pt-16 md:pt-20 lg:pt-36
  `}
    >
      <img
        className="absolute left-[50%] top-0 max-w-[95vw] xl:max-h-full"
        style={{ transform: "translate(-50%, 0)" }}
        // src={isMobileOnly ? mobileBg : desktopBg}
        src={width < 640 ? mobileBg : desktopBg}
        align="background"
      />
      <Heading
        className="sm:max-w-[450px] lg:max-w-[600px] mx-auto relative px-4 lg:px-0"
        injectionType={2}
        withBlueSymbol
      >
        {title}
      </Heading>
      <p className="text-black-200 text-p sm:max-w-[380px] md:max-w-[776px] mx-auto relative px-4">
        {subTitle}
      </p>

      <CtaPrimary
        className="mx-auto mt-6 md:mt-8 relative"
        title={cta.title}
        target={cta.target}
      />
    </section>
  );
};

RequestIntegration.propTypes = {
  className: string,
  title: string.isRequired,
  subTitle: string.isRequired,
  cta: shape({
    ctaTitle: string,
    ctaTarget: string,
  }),
};

RequestIntegration.defaultProps = {
  className: "",
  cta: null,
};

export default RequestIntegration;
